import { gql } from "apollo-boost"

import { FEATURE_HIDE_IN_PRODUCTION, FEATURE_HIDE_IN_PRODUCTION_ARTICLE } from "lacinetek-common/src/metadata"

export * from "./admin-queries"
import { cart } from "../common.js"

export const FETCH_PAGE = gql`
	query FetchPage($query: String!) {
		cms {
			pages(query: $query) {
				items {
					id
					linkRewrite
					metaTitle
					content
					metaDescription
				}
			}
		}
	}
`

export const FETCH_PAGE_BY_ID = gql`
	query FetchPageById($id: ID!) {
		cms {
			pages(includeIds: [$id]) {
				items {
					content
				}
			}
		}
	}
`

export const FETCH_LANGUAGES = gql`
	query FetchLanguages {
		platform {
			languages(query: "active: true") {
				items {
					id,
					name,
					isoCode
				}
			}
		}
	}
`

export const FETCH_SUBSCRIPTIONS = gql`
	query FetchSubscriptions($ids: [ID!]) {
		cms {
			products(includeIds: $ids, query:"type:SVOD", perPage: 50) {
				items {
					id
					name
					descriptionShort
					type
					extension {
						... on ProductSVOD {
							autoRenewal
							accessModes {
								price
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_FESTIVAL_SUBSCRIPTIONS = gql`
	query FetchFestivalSubscriptions($ids: [ID!]) {
		cms {
			products(query:"type:SVOD", includeIds: $ids, perPage: 50) {
				items {
					id
					name
					descriptionShort
				}
			}
		}
	}
`

export const FETCH_PREPAYMENTS = gql`
	query FetchPrepayments {
		cms {
			products(query:"type:PREPAYMENT") {
				items {
					id
					name
					type
					extension {
						... on ProductPrepayment {
							amount
							accessModes {
								price,
								priceFormat
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCTS = gql`
	query FetchProducts($page: Int, $perPage: Int = 20) {
		cms {
			products(perPage: $perPage, page: $page, query:"type:TVOD", sort: {field: "position", order: Asc}) {
				pagination {
					page
					lastPage
					total
				}
				items {
					id
					name
					type
					descriptionShort
					description
					linkRewrite
					dateFrom
					metadata {
						name
						value
					}
					images {
						id
						source
						type
					}
					metaTitle
					metaDescription
					metaKeywords
					extension {
						... on ProductTVOD {
							directors {
								items {
									director {
										id
										name
										images { source type }
										metadata(include: "genre") { name value }
									}
									roles
								}
							},
							actors {
								items {
									actor {
										id
										name
									}
									roles
								}
							}
							extracts(query: "mediaSource: (1 OR 2 OR 3 OR 4)") {
								items {
									id
									description
									cover
									product {
										name
										linkRewrite
									}
									metadata {
										name
										value
									}
								}
							}
							videos {
								items {
									description
									cover
									duration
									metadata {
										name
										value
									}
									accessInfo {
										streaming
										download
									}
									subtitles {
										name
										lang
									}
								}
							}
							accessModes {
								id
								price
								priceFormat
								... on TVODAccessMode {
									type
									mode
									quality
									onlyVideos {
										name
										subtitles{ id lang }
									}
								}
							}
							categories(perPage: 50) {
								items {
									id
									name
									parent {
										id
										parent { id }
									}
									banner
									images {
										source
										type
									}
									linkRewrite
									metadata {
										name
										value
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCTS_BY_IDS = gql`
	query FetchProductsByIds($ids: [ID!]) {
		cms {
			products(perPage: 50 includeIds: $ids query:"type:TVOD", sort: {field: "position", order: Asc}) {
				items {
					id
					name
					type
					descriptionShort
					linkRewrite
					dateFrom
					metadata {
						name
						value
					}
					images {
						id
						source
						type
					}
					extension {
						... on ProductTVOD {
							directors {
								items {
									director {
										id
										name
										image
									}
									roles
								}
							}
							actors {
								items {
									actor { id name }
									roles
								}
							}
							extracts(query: "mediaSource: (1 OR 4)") { items { id, description, cover, product { name, linkRewrite }, metadata { name, value }} },
							videos { items { description cover duration metadata { name value } } }
							accessModes {
								id
								price
								priceFormat
								... on TVODAccessMode {
									type
									mode
									quality
									onlyVideos {
										subtitles{ id lang }
									}
								}
							}
							categories {
								items {
									id
									name
									parent {
										id
										parent { id }
									}
									banner
									images { source, type }
									linkRewrite
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCT = gql`
	query FetchProduct($id: ID!) {
		cms {
			products(includeIds: [$id], query:"type:TVOD", sort: {field: "position", order: Asc}) {
				items {
					id,
					name,
					type
					linkRewrite,
					metadata {
						name,
						value
					},
					dateFrom,
					images {
						id
						source
						type
					},
					extension {
						... on ProductTVOD {
							directors {
								items {
									director {
										id
										name
										image
										metadata {
											name
											value
										}
									}
									roles
								}
							},
							actors {
								items {
									actor { id name }
									roles
								}
							},
							extracts(query: "mediaSource: (1 OR 4)") { items { id, description, cover, product { name, linkRewrite }, metadata { name, value }} },
							videos { items { description, cover, duration, metadata { name, value } } }
							accessModes {
								id
								price
								priceFormat
								... on TVODAccessMode {
									type
									mode
									quality
									onlyVideos {
										subtitles{ id lang }
									}
								}
							}
							categories {
								items {
									id,
									name,
									parent {
										id
										parent { id }
									},
									banner,
									images { source, type }
									linkRewrite
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCT_CATEGORIES = gql`
query FetchProductCategories($id: ID!) {
	cms {
			products(includeIds: [$id], perPage: 50, page: 1) {
					items {
							id
							name
							extension {
									...on ProductTVOD {
											categories {
													pagination { total }
													items {
															id
													}
											}
									}
							}
					}
			}
	}
}
`

export const FETCH_PRODUCT_PRICES = gql`
	query Products($id: ID!) {
		cms {
			products (includeIds: [$id], query: "type:TVOD") {
				items {
					id
					extension {
						... on ProductTVOD {
							accessModes {
								id
								type
								quality
								price
								priceNoDiscount
								onlyVideos {
									name
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCT_SEO = gql`
	query ProductSeo($id: ID!) {
		cms {
			products(includeIds: [$id], query: "type: TVOD") {
				items {
					metaTitle
					metaDescription
					metaKeywords
				}
			}
		}
	}
`

export const FETCH_PRODUCT_SEO_BY_SLUG = gql`
	query ProductSeo($query: String!) {
		cms {
			products(query: $query) {
				items {
					metaTitle
					metaDescription
					metaKeywords
				}
			}
		}
	}
`

export const FETCH_PRODUCT_ACCESSES = gql`
query FetchProductAccesses($id: ID!) {
	cms {
		products(includeIds: [$id]) {
			pagination {
				page
				lastPage
			}
			items {
				extension {
					accessModes {
						id
						price
					}
				}
			}
		}
	}
}
`

export const FETCH_PRODUCT_BY_SLUG = gql`
	query FetchProductBySlug($query: String!) {
		cms {
			products(query: $query) {
				items {
					id,
					name,
					type
					descriptionShort,
					cover,
					linkRewrite,
					metadata {
						name,
						value
					},
					dateFrom,
					images {
						id
						source
						type
					},
					extension {
						... on ProductTVOD {
							directors {
								items {
									director {
										id
										name
										image
										metadata {
											name
											value
										}
									}
									roles
								}
							},
							actors {
								items {
									actor { id name }
									roles
								}
							},
							extracts(query: "mediaSource: (1 OR 4)") { items { id, description, cover, product { name, linkRewrite }, metadata { name, value }} },
							videos { items { description, cover, duration, metadata { name, value } } }
							accessModes {
								id
								price
								priceFormat
								... on TVODAccessMode {
									type
									mode
									quality
									onlyVideos {
										subtitles{ id lang }
									}
								}
							}
							categories {
								items {
									id,
									name,
									parent {
										id
										parent { id }
									},
									banner,
									images { source, type }
									linkRewrite
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PROFILE = gql`
	query FetchProfile {
		user {
			profile {
				id
				dateAdd
				firstname
				lastname
				email
				birthdate
				address {
					country { isoCode }
				}
				language {isoCode }
			}
			groups {
				id
				name
			}
			devices {
				id
				dateUpd
				fingerprint
				os
				type
				application
			}
		}
	}
`

export const FETCH_USER_BALANCE = gql`
	query FetchUserBalance {
		billing {
			prepayment {
				balance {
					type
					amount
				}
			}
		}
	}
`

export const FETCH_ACCESS = gql`
	query FetchAccess($id: ID!) {
		cms {
			products(includeIds: [$id]) {
				items {
					id
					name
					extension {
						... on ProductTVOD {
							user {
								accesses {
									items {
										id
										accessMode {
											... on TVODAccessMode {
												type
												mode
												quality
												price
											}
										}
									}
								}
							}
							videos(query: "mediaSource: 4") {
								items {
									id
									name
									accessInfo {
										streaming
										qualityHD
										qualitySD
									}
								}
							}
						}
					}
				}
			}
		}
	}
`


export const FETCH_ACCESSES_AMOUNT = gql`
	query FetchAccessesAmount {
		user {
			accesses {
				pagination {
					total
				}
			}
		}
	}
`

export const FETCH_ACCESSES_TVOD = gql`
	query FetchAccessesTvod($query: String, $page: Int, $perPage: Int) {
		user {
			accesses(query: $query, page: $page, perPage: $perPage) {
				pagination {
					page
					lastPage
					total
				}
				items {
					id
					type
					recurring
					dateAdd
					dateExp
					dateRenewal
					order {
						dateAdd
					}
					...on ProductAccess {
						accessMode {
							...on TVODAccessMode {
								type
								mode
								quality
								price
							}
						}
						product {
							id
							name
							extension {
								...on ProductTVOD {
									videos(query: "mediaSource: (1 OR 4)") {
										items {
											id
											duration
											accessInfo {
												expires
												qualitySD
												qualityHD
											}
											userStats {
												plays
												seek
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_ACCESSES_SVOD = gql`
	query FetchAccessesSVOD($page: Int! = 1, $query: String) {
		user {
			accesses(perPage: 50, page: $page, query: $query) {
				pagination {
					page
					total
					lastPage
				}
				items {
					id
					dateAdd
					dateExp
					dateRenewal
					recurring
					...on ProductAccess {
						order {
							id
							paymentGateway
						}
						product {
							id
							name
							extension {
								...on ProductSVOD {
									autoRenewal
									trialPeriod
									categories(perPage: 50) {
										pagination {
											page
											total
										}
										items {
											id
											name
											parent {
												id
												name
											}
											metadata {
												name
												value
											}
											products(perPage: 50) {
												items {
													id
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_COUNTRIES = gql`
	query FetchCountries {
		platform {
			countries {
				items {
					id,
					isoCode
				}
			}
		}
	}
`

export const FETCH_CATEGORY = gql`
	query FetchCategory($id: ID!, $page: Int!){
		cms {
			categories(includeIds: [$id]) {
				items {
					id
					name
					description
					descriptionShort
					metadata {
						name
						value
					}
					banner
					images {
						source
						type
					}
					children(sort: { field: "position", order: Asc}, perPage: 2, page: $page) {
						pagination {
							page
							total
							lastPage
						}
						items {
							id
							name
							descriptionShort
							description
							linkRewrite
							banner
							images {
								source
								type
							}
							metadata {
								name
								value
							}
							parent { id }
							products(query:"type:TVOD", perPage: 50, sort: {field: "position", order: Asc}) {
								items {
									id
								}
							}
							children {
								items {
									name
									products { items { id } }
								}
							}
						}
					},
					linkRewrite
				}
			}
		}
	}
`

export const FETCH_CATEGORY_LIGHT = gql`
	query FetchCategoryLight($id: ID!, $page: Int!){
		cms {
			categories(includeIds: [$id]) {
				items {
					id,
					name,
					banner,
					images {
						source
						type
					}
					children(sort: { field: "position", order: Asc}, perPage: 15, page: $page) {
						pagination {
							page
							total
							lastPage
						}
						items {
							id
							name
							descriptionShort
							linkRewrite
							banner
							images {
								source
								type
							}
							parent { id }
						}
					}
				}
			}
		}
	}
`

export const FETCH_ALL_SELECTIONS = gql`
	query FetchAllSelection($countryQuery: String!, $yearQuery: String!){
    cms {
      categories(query: $countryQuery) {
        items {
          id,
          children(query: $yearQuery, sort: { field: "position", order: Asc}, perPage: 12) {
            items {
              id
              name
							descriptionShort
							linkRewrite
							images { source type }
							metadata { name value }
            }
          }
        }
      }
    }
  }
`

export const FETCH_YEAR_SELECTION = gql`
	query FetchAllSelection($countryQuery: String!){
    cms {
      categories(query: $countryQuery) {
        items {
          id,
          children(sort: { field: "id", order: Asc}, perPage: 1) {
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const FETCH_TREASURES = gql`
	query FetchTreasures($id: ID!){
		cms {
			categories(includeIds: [$id]) {
				items {
					id
					descriptionShort
					description
					metaTitle
					metaDescription
					metaKeywords
					metadata { name value }
					children(sort: { field: "position", order: Asc}, perPage: 50) {
						pagination {
							page,
							total,
							lastPage
						}
						items {
							id,
							name,
							linkRewrite,
							images { source type }
							#products(sort: {field: "position", order: Asc}) {items {id}}
						}
					}
				}
			}
		}
	}
`

export const FETCH_LISTS = gql`
	query FetchCategory($id: ID!, $page: Int!){
		cms {
			categories(includeIds: [$id]) {
				items {
					children(sort: { field: "position", order: Asc}, perPage: 50, page: $page) {
						pagination {
							page,
							total,
							lastPage
						}
						items {
							id,
							name,
							descriptionShort,
							description
							linkRewrite,
							metaTitle
							metaDescription
							banner
							images { source, type }
							metadata { name value }
							parent { id }
							products(query:"type:TVOD", perPage: 50, sort: {field: "position", order: Asc}) {
								pagination { page, lastPage }
								items {
									id
								}
							}
							children {
								items {
									id
									name
									descriptionShort
									products(query:"type: TVOD", perPage: 50, sort: {field: "position", order: Asc}) {
										pagination { page, lastPage }
										items { id }
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_LIST_PRODUCTS = gql`
	query FetchListProducts($id: ID!, $page: Int!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					products(page: $page, perPage: 50, sort: {field: "position", order: Asc}) {
						pagination { page, lastPage }
						items {
							id
						}
					}
				}
			}
		}
	}
`

export const FETCH_CATEGORIES = gql`
	query FetchCategories($ids: [ID!]){
		cms {
			categories(includeIds: $ids, perPage: 50) {
				items {
					id
					name
					children(sort: { field: "position", order: Asc}, perPage: 50) {
						items {
							id
							name
							description
							descriptionShort
						}
					}
				}
			}
		}
	}
`

export const FETCH_CATEGORIES_BY_NAME = gql`
	query FetchCategoriesByName($query: String!) {
		cms {
			categories(perPage: 50, query: $query) {
				items {
					id,
					name,
					images { source, type }
					descriptionShort
				}
			}
		}
	}
`

export const FETCH_COMPLETE_CATEGORY = gql`
	query FetchCompleteCategory($id: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					id,
					name,
					descriptionShort,
					description,
					metadata {
						name,
						value
					}
					banner,
					images { source, type }
					linkRewrite,
					products(query:"type:TVOD", perPage: 50, sort: {field: "position", order: Asc}) {
						items {
							id
						}
					}
				}
			}
		}
	}
`

export const FETCH_COMPLETE_CATEGORY_BY_SLUG = gql`
	query FetchCategoryBySlug($query: String!) {
		cms {
			categories(query: $query) {
				items {
					id,
					name,
					descriptionShort,
					description,
					metadata {
						name,
						value
					},
					parent { id }
					banner,
					images { source, type }
					linkRewrite,
					children(sort: { field: "position", order: Asc}, perPage: 50) {
						pagination {
							page,
							lastPage
						}
						items {
							id
						}
					}
					products(query:"type:TVOD", perPage: 50, sort: {field: "position"}) {
						items {
							id
						}
					}
				}
			}
		}
	}
`

export const FETCH_SVOD_CATEGORIES = gql`
query FetchSvodCategories($query: String!) {
	cms {
		categories(query: $query) {
			items {
				id
				name
				description
				descriptionShort
				metadata {
						name
						value
				}
				images {
					source
					type
				}
				parent { id }
				linkRewrite,
				children(sort: { field: "position", order: Asc}, perPage: 50) {
					pagination {
							page,
							lastPage
							total
					}
					items {
						id
						name
						description
						descriptionShort
						metadata {
							name
							value
						}
						banner
						images {
							source
							type
						}
						linkRewrite
						products(query:"type:TVOD", perPage: 50) {
							pagination {
								page
								lastPage
								total
							}
							items {
								id
								metadata {
									name
									value
								}
							}
						}
						videos(page: 1, perPage: 50) {
							pagination {
								total
							}
							items {
								id
								thumbnail
							}
						}
						children(sort: { field: "position", order: Asc}, perPage: 50) {
							pagination {
								page
								lastPage
								total
							}
							items {
								id
								name
								description
								descriptionShort
								metadata { name, value }
								banner
								images { source, type }
								products(query:"type:TVOD", perPage: 50) {
									pagination {
										page
										lastPage
										total
									}
									items {
										id
										metadata {
											name
											value
										}
									}
								}
								videos(page: 1, perPage: 50) {
									pagination {
										total
									}
									items {
										id
										thumbnail
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
`

export const FETCH_SVOD_PRODUCTS = gql`
query FetchSvod2Products($query: String!) {
	cms {
			categories(query: $query) {
					items {
							id
							name
							parent {
									id
									name
							}
							children(sort: { field: "position", order: Asc}, perPage: 50) {
									items {
											id
											name
											products(query:"type:TVOD", perPage: 50) {
													items {
															id
													}
											}
											children(sort: { field: "position", order: Asc}, perPage: 50) {
													items {
															id
															name
															metadata {
																name
																value
															}
															children(sort: { field: "position", order: Asc}, perPage: 50) {
																	pagination {
																			page
																			lastPage
																			total
																	}
																	items {
																			id
																			name
																	}
															}
															products(query:"type:TVOD", perPage: 50) {
																	items {
																			id
																	}
															}
													}
											}
									}
							}
					}
			}
	}
}
`

export const FETCH_SVOD_OFFERS = gql`
query FetchSvod2Offers($query: String!) {
	cms {
		categories(query: $query) {
			items {
				id
				name
				description
				descriptionShort
				metadata {
						name
						value
				}
				parent { id }
				linkRewrite,
				children(sort: { field: "position", order: Asc}, perPage: 50) {
					pagination {
							page,
							lastPage
							total
					}
					items {
						id
						name
						description
						descriptionShort
						metadata {
							name
							value
						}
						banner
						images {
							source
							type
						}
						children(sort: { field: "position", order: Asc}, perPage: 50) {
							pagination {
								page
								lastPage
								total
							}
							items {
								id
								name
								description
								descriptionShort
								metadata { name, value }
								banner
								images { source, type }
								children(sort: { field: "position", order: Asc}, perPage: 50) {
									pagination {
										page
										lastPage
										total
									}
									items {
										id
										name
										description
										descriptionShort
										metadata { name, value }
										banner
										images { source, type }
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
`

export const FETCH_LIST = gql`
	query FetchListOf($query: String!) {
    cms {
      categories(query: $query) {
        items {
          id
          name
          descriptionShort
          description
          images { source type }
					metaTitle
					metaDescription
          linkRewrite
          children(sort: { field: "position", order: Asc}, perPage: 50) {
            items {
              id
              name
              descriptionShort,
              linkRewrite
              products(query:"type:TVOD", perPage: 50, sort: {field: "position", order: Asc}) {
                items {
									id
                }
              }
            }
            pagination {
              page,
              lastPage
            }
          }
          products(query:"type:TVOD", perPage: 50, sort: {field: "position", order: Asc}) {
            items {
              id
            }
          }
        }
      }
    }
  }
`

export const FETCH_TREASURE = gql`
	query FetchTreasure($query: String!) {
		cms {
			categories(query: $query) {
				items {
					id
					name
					descriptionShort
					description
					metadata {
						name
						value
					},
					images { source type }
					linkRewrite
					children(sort: { field: "position", order: Asc}, perPage: 50) {
						items {
							id
							name
							linkRewrite
							images { source, type }
						}
					}
					products(query:"type:TVOD", perPage: 50, sort: {field: "position", order: Asc}) {
						items {
							id
						}
					}
				}
			}
		}
	}
`

export const FETCH_TREASURE_LIGHT = gql`
	query FetchTreasure($id: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					id
					name
					linkRewrite
					descriptionShort
				}
			}
		}
	}
`

export const FETCH_CORPUS = gql`
	query FetchCorpus($query: String!) {
		cms {
			categories(query: $query) {
				items {
					id
					name
					descriptionShort
					description
					metadata {
						name
						value
					}
					metaTitle
					metaDescription
					metaKeywords
					images { source type }
					linkRewrite
					children(sort: { field: "position", order: Asc}, perPage: 50) {
						items {
							id
							name
							linkRewrite
							images { source, type }
							products(perPage: 50) { items { id } }
						}
					}
					products(query:"type:TVOD", perPage: 50, sort: {field: "position", order: Asc}) {
						items {
							id
						}
					}
				}
			}
		}
	}
`

export const FETCH_DIRECTORS = gql`
	query FetchDirectors($page: Int!) {
		cms {
			directors(perPage: 50, page: $page) {
				pagination { page, lastPage, total }
				items {
					id,
					name,
					images { source type }
				}
			}
		}
	}
`

export const FETCH_DIRECTORS_BY_IDS = gql`
	query FetchDirectorsByIds($page: Int!, $ids: [ID!]) {
		cms {
			directors(perPage: 50, page: $page, includeIds: $ids) {
				pagination { page, lastPage, total }
				items {
					id,
					name,
					linkRewrite,
					description,
					descriptionShort,
					images { source type }
					products(sort: {field: "position", order: Asc}) {
						items {
							product {
								id
							}
							roles
						}
					}
				}
			}
		}
	}
`

export const FETCH_DIRECTORS_BY_NAME = gql`
	query FetchDirectorsByName($query: String!) {
		cms {
			directors(perPage: 50, query: $query) {
				items {
					id,
					name,
					image
				}
			}
		}
	}
`

export const FETCH_PRODUCTS_BY_NAME = gql`
	query FetchProductsByName($query: String!) {
		cms {
			products(perPage: 50, query: $query, sort: {field: "position", order: Asc}) {
				items {
					id,
					name,
					type
					images {
						source
						type
					}
				}
			}
		}
	}
`

export const FETCH_DIRECTOR = gql`
	query FetchDirector($id: ID!) {
		cms {
			directors(includeIds: [$id]) {
				items {
					id,
					name,
					image,
					images {source type}
					description,
					products(query:"type:TVOD", sort: {field: "position", order: Asc}) {
						items {
							product {
								id
								name
								type
								linkRewrite
								metadata {
									name
									value
								}
							}
							roles
						}
					}
				}
			}
		}
	}
`

export const FETCH_ACTOR = gql`
	query FetchActor($id: ID!) {
		cms {
			actors(includeIds: [$id]) {
				items {
					id,
					name,
					images {source type}
				}
			}
		}
	}
`

export const FETCH_ACQUISITIONS = gql`
	query FetchAquisitions($countryQuery: String!) {
		cms {
			categories(query: $countryQuery) {
				items {
					name
					descriptionShort
					products(perPage: 50, sort: {field: "position", order: Asc}) {
						items {
							id
						}
					}
				}
			}
		}
	}
`

export const FETCH_ALL_ACQUISITIONS = gql`
	query FetchAllAquisitions($countryQuery: String!) {
		cms {
			categories(query: $countryQuery) {
				items {
					name
					children {
						items {
							id
							name
							children {
								items {
									name
									products(perPage: 50, sort: {field: "position", order: Asc}) {
										items {
											id
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_COIL = gql`
	query FetchCoil($id: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					products(perPage: 50) {
						items {
							id
						}
					}
				}
			}
		}
	}
`

export const FETCH_COUNTRY_COIL = gql`
	query FetchCountryCoil($countryQuery: String!) {
		cms {
			categories(query: $countryQuery) {
				items {
					products(perPage: 50) {
						items {
							id
						}
					}
				}
			}
		}
	}
`

export const FETCH_ACCESS_VIDEO = gql`
	query FetchAccessVideo($id: ID!) {
		cms {
			products(perPage: 1, includeIds: [$id], sort: {field: "position", order: Asc}) {
				items {
					extension {
						... on ProductTVOD {
							videos(query: "mediaSource: 4") {
								items {
									id
									name
									accessInfo {
										streaming
										download
										qualityHD
										qualitySD
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_VIDEO_PLAYER = gql`
	query FetchVideoPlayer($id: ID!) {
		cms {
			videos(includeIds: [$id]) {
				items {
					player { url }
				}
			}
		}
	}
`

export const FETCH_EXTRACT_PLAYER = gql`
	query FetchExtractPlayer($id: ID!) {
		cms {
			extracts(includeIds: [$id]) {
				items {
					id
					player {
						url
					}
					mediaSource {
						id
					}
				}
			}
		}
	}
`

export const FETCH_KINOW_CATEGORY_VIDEO = gql`
	query FetchCategoryVideoPlayer($id: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					videos(perPage: 1, query: "mediaSource: 4") {
						items {
							player {
								url
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_YOUTUBE_CATEGORY_VIDEO = gql`
	query FetchCategoryVideoPlayer($id: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					videos(perPage: 1, query: "mediaSource: 1") {
						items {
							player {
								url
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_KINOW_CATEGORY_VIDEO_ID = gql`
	query FetchKinowCategoryVideoId($id: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					videos(perPage: 1, query: "mediaSource: 4") {
						items {
							id
						}
					}
				}
			}
		}
	}
`

export const FETCH_YOUTUBE_CATEGORY_VIDEO_ID = gql`
	query FetchYoutubeCategoryVideoId($id: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					videos(perPage: 1, query: "mediaSource: 1") {
						items {
							id
						}
					}
				}
			}
		}
	}
`

export const FETCH_CATEGORY_VIDEO_PLAYER = gql`
	query FetchCategoryVideoPlayer($id: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					videos(perPage: 1) {
						items {
							player {
								url
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_CATEGORY_VIDEO_PLAYER_BY_VIDEO_ID = gql`
	query FetchCategoryVideoPlayer($id: ID!, $videoId: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					videos(includeIds: [$videoId]) {
						items {
							player {
								url
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCT_EXTRACT = gql`
	query FetchProductExtractPlayer($id: ID!) {
		cms {
			products(includeIds: [$id]) {
				items {
					extension {
						... on ProductTVOD {
							extracts(query: "mediaSource: (1 OR 2 OR 3 OR 4)") {
								items {
									id,
									description,
									cover
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCT_EXTRACT_PLAYER = gql`
	query FetchProductExtractPlayer($id: ID!) {
		cms {
			products(includeIds: [$id]) {
				items {
					extension {
						... on ProductTVOD {
							extracts(query: "mediaSource: (1 OR 3 OR 4)") {
								items {
									id
									player {
										url
									}
									mediaSource {
										id
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_CART = gql`
	query FetchCart {
		billing{
			cart {
				${cart}
			}
		}
	}
`

export const FETCH_MONTH_LIST = gql`
	query FetchMonthList {
    cms {
      topMenuWidget {
        elements(sort: { field: "position" }) {
          items {
            position
						targetType
            target {
              ... on Category {
                id
                name
								descriptionShort
                images { source type }
                linkRewrite
              }
							... on Product {
                id
              }
            }
          }
        }
      }
    }
	}
`

export const FETCH_SELECTION = gql`
	query FetchSelection($query: String!, $countryQuery: String!) {
		cms {
			categories(query: $countryQuery) {
				items {
					children(query: $query) {
						items {
							id
							name
							descriptionShort
							description
							metaTitle
							metaDescription
							metadata {
								name
								value
							}
							images { source type }
							linkRewrite
							products(query:"type:TVOD" perPage: 50 sort: {field: "position" order: Asc}) {
								items {
									id
								}
							}
							children {
								items {
									name
									products (sort: {field: "position", order: Asc}) {
										items {
											id
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

const blogCategory = (perPage, query = "") => `
	items {
		id
		name
		linkRewrite
		posts(
			sort: {field: "dateIssue", order: Desc}
			query: "${query}"
			perPage: ${perPage}
		) {
			items {
				dateIssue
				title
				linkRewrite
				cover
				description
				descriptionShort
				metaTitle
				metaDescription
				metaKeywords
				metadata {
					name
					value
				}
			}
		}
	}
`

export const FETCH_BLOG_CATEGORY = (env) => {
	let query = ""
	if (env === "production") {
		query = `NOT ${FEATURE_HIDE_IN_PRODUCTION_ARTICLE}`
	}

	return gql`
		query FetchBlogCategory($id: ID!) {
			cms {
				blogCategories(includeIds: [$id]) {
					${blogCategory(10, query)}
				}
			}
		}
	`
}

export const FETCH_BLOG_CATEGORY_BY_SLUG = gql`
	query FetchBlogCategoryBySlug($query: String!) {
		cms {
			blogCategories(query: $query) {
				${blogCategory(10)}
			}
		}
	}
`

export const FETCH_COMPLETE_BLOG_CATEGORY_BY_SLUG = gql`
	query FetchBlogCategoryBySlug($query: String!, $page: Int! = 1) {
		cms {
			blogCategories(query: $query) {
				items {
					id
					name
					linkRewrite
					posts(sort: {field: "dateIssue", order: Desc}, perPage: 50, page: $page) {
						items {
							dateIssue
							title
							linkRewrite
							cover
							description
							descriptionShort
							metaKeywords
							metadata {
								name
								value
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PARTNER_CATEGORIES = gql`
	query FetchCategory($query: String!){
		cms {
				categories(query: $query) {
						items {
								id
								name
								linkRewrite
								images {
										source
										type
								}
								metadata {
										name
										value
								}
						}
				}
		}
	}
`

export const FETCH_BONUSES = gql`
	query FetchBonuses($query: String!) {
		cms {
			extracts(query: $query, perPage: 50) {
				pagination {
					page
					lastPage
					total
				}
				items {
					id
					name
					metadata {
						name
						value
					}
					cover
					thumbnail
					description
					thumbnail
					product {
						id
						name
						linkRewrite
						extension {
							... on ProductTVOD {
								directors(includeRoles: ["Réalisateur"]) {
									items {
										director {
											id
											name
										}
										roles
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_SELECTIONS = gql`
	query FetchSelections {
		cms {
			carouselWidget {
				slides(sort: { field: "position" }) {
					items {
						position
						label
						image
						textLayer {
							text
							button
							opacity
						}
						targetType
						target {
							... on Category {
								id
								name
								images { source type }
								linkRewrite
								metadata(include: ["${FEATURE_HIDE_IN_PRODUCTION}"]) {
									name
									value
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_CINECLUB_MAIN_CATEGORY = gql`
	query FetchCineClubMainCategory($id: ID!) {
		cms {
			categories(includeIds: [$id], sort: { field: "position", order: Asc}) {
				items {
					id
					metadata { name value }
				}
			}
		}
	}
`

export const FETCH_CINECLUB = gql`
	query FetchCineClub($id: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					id
					children(perPage: 50 sort: { field: "position", order: Desc }) {
						items {
							id
							name
							description
							descriptionShort
							metadata { name value }
							images { source type }
							videos(perPage: 1, page: 1) {
								items {
									id
									cover
								}
							}
							products(perPage: 1) {
								items {
									id
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_ORDERS = gql`
	query	FetchOrders($page: Int!) {
		billing {
			orders(perPage: 10 page: $page query:"state.status:COMPLETED") {
				pagination {
					page
					lastPage
					total
				}
				items {
					id
					dateAdd
					dateUpd
					state {
						id
						date
						status
					}
					stateHistory {
						id
						date
						status
					}
					cart {
						content {
							items {
								product {
									id
									name
									type
								}
								gift {
									id
									email
									firstname
									lastname
									message
									custom
									dateSend
								}
							}
						}
						totalPrice
						totalPriceWithoutTax
					}
				}
			}
		}
	}
`

export const FETCH_INVOICE = gql`
	query	FetchOrders($id: ID!) {
		billing {
			orders(includeIds:[$id]) {
				items {
					invoice
				}
			}
		}
	}
`


export const FETCH_LAST_ORDER = gql`
	query FetchLastOrder {
		billing {
			orders(sort: { field: "dateAdd", order: Desc}, perPage: 1) {
				items {
					id
					dateAdd
					dateUpd
					paymentGateway
					cart {
						id
						totalPrice
						totalPriceWithoutTax
						vouchers {
							name
						}
						content(perPage: 50) {
							items {
								accessMode {
									id
									price
									prepaymentBonuses {
										items {
											id
											name
											balanceType
											amount
										}
									}
									... on TVODAccessMode {
										type
										mode
										quality
									}
								}
								product {
									name
									id
									type
									extension {
										...on ProductSVOD {
											autoRenewal
											trialPeriod
										}
										... on ProductTVOD {
											directors {
												items {
													director {
														id
														name
													}
													roles
												}
											}
										}
									}
								}
								gift {
									id
									firstname
									lastname
									email
									message
									dateSend
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_DEVICES = gql`
	query FetchDevices {
		user {
			devices {
				id
				dateUpd
				fingerprint
				os
				type
				application
			}
		}
	}
`

export const FETCH_PAYMENT_GATEWAYS = gql`
	query FetchPaymentGateways {
		billing {
			paymentGateways {
				name
				methods {
					identifier
					metadata
				}
			}
		}
	}
`

export const FETCH_DIRECTOR_PRODUCTS = gql`
	query FetchDirectorProducts($id: ID!) {
		cms {
			directors(includeIds: [$id]) {
				items {
					products(perPage: 50, sort: {field: "position", order: Asc}) {
						items {
							product {
								id
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_WATCH_SESSIONS = gql`
	query FetchWatchSessions($ids: [ID!]) {
		cms {
			products(includeIds: $ids) {
				items {
					id
					extension {
						... on ProductTVOD {
							videos {
								items {
									userStats {
										plays
										watchSessions {
											date
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PLATFORM_ACCESS = gql`
	query FetchPlatformAccessILoveThomas {
		platform {
			access {
				purchase
				country
			}
			i18n {
				country {
					isoCode
				}
			}
		}
	}
`

export const FETCH_FESTIVALS = gql`
	query FetchFestivals($query: String!) {
		cms {
			categories(query: $query) {
				items {
					id
					name
					linkRewrite
					metadata { id name value }
				}
			}
		}
	}
`

export const FETCH_FESTIVAL = gql`
	query FetchFestival($query: String!) {
		cms {
			categories(query: $query) {
				items {
					id
					name
					descriptionShort
					description
					metaTitle
					metaDescription
					metadata { name value }
					images { source type }
					videos { items { id } }
					products(perPage: 50, sort: {field: "position", order: Asc}) {
						pagination { page total lastPage }
						items {
							id
							metadata {
								name
								value
							}
						}
					}
					children {
						items {
							name
							products(perPage: 50, sort: {field: "position", order: Asc}) {
								items {
									id
									metadata {
										name
										value
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_FESTIVAL_LIVE = gql`
	query FetchFestivalLive($id: ID!) {
		cms {
			categories(includeIds: [$id]) {
				items {
					videos {
						items {
							player {
								url
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCT_DETAILS = gql`
	query FetchProductDetails($id: ID!) {
		cms {
			products(includeIds: [$id]) {
				items {
					metadata {
						name
						value
					}
					extension {
						...on ProductTVOD {
							directors {
								items {
									director { id name }
									roles
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCTS_VIDEOS = gql`
	query FetchProductsVideos($ids: [ID!]) {
		cms {
			products(includeIds: $ids, perPage: 50, page: 1) {
				items {
					id
					extension {
						...on ProductTVOD {
							videos(query: "mediaSource: 4") {
								items {
									id
									name
									accessInfo {
										streaming
										download
										qualityHD
										qualitySD
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCT_METADATA_AND_DIRECTORS = gql`
	query FetchProductDetails($id: ID!) {
		cms {
			products(includeIds: [$id]) {
				items {
					metadata {
						name
						value
					}
					extension {
						...on ProductTVOD {
							directors (perPage: 50) {
								items {
									director { id name }
									roles
								}
							}
							videos(query: "!Freebox") {
								items {
									name
									metadata {
										name
										value
									}
									subtitles {
										lang
										name
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_PRODUCTS_METADATA_AND_IMAGES = gql`
	query FetchProductsMetadata($ids: [ID!]) {
		cms {
			products(includeIds: $ids) {
				items {
					id
					images { id source type }
					metadata { name value }
				}
			}
		}
	}
`

export const FETCH_LOCALE_LINK = (type, lang) => gql`
	query Fetch${type}${lang}Link($query: String!) {
		cms {
			${type}(query: $query) {
				items {
					linkRewrite
				}
			}
		}
	}
`

export const FETCH_BUNDLES = gql`
	query FetchBundles($query: String!) {
		cms {
			products(query: $query) {
				items {
					metadata {
						name
						value
					}
					id
					name
					type
					extension {
						...on ProductBundle {
							accessModes {
								id
								type
								quality
								price
								priceNoDiscount
							}
							products {
								items {
									id
									name
									type
									linkRewrite
									images {
										id
										source
										type
									}
									extension {
										...on ProductTVOD {
											directors {
												items {
													director {
														id
														name
													}
													roles
												}
											}
											videos {
												items {
													language {
														isoCode
													}
													subtitles{
														id
														lang
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const FETCH_BUNDLE_PRICES = gql`
	query BundlePrices($id: ID!) {
		cms {
			products (includeIds: [$id], query: "type:BUNDLE") {
				items {
					id
					extension {
						... on ProductBundle {
							accessModes {
								id
								type
								quality
								price
								priceNoDiscount
							}
						}
					}
				}
			}
		}
	}
`
